var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <h2 class=\"l-float-left l-margin-top "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"textEmphasis") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":41},"end":{"line":2,"column":79}}})) != null ? stack1 : "")
    + "\">"
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? lookupProperty(stack1,"titleText") : stack1), depth0)) != null ? stack1 : "")
    + "</h2>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "t-extend-h1";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <h3 class=\"l-float-left "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"textEmphasis") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":28},"end":{"line":6,"column":66}}})) != null ? stack1 : "")
    + " l-margin-top-none\">"
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"subTitle") : depth0)) != null ? lookupProperty(stack1,"titleText") : stack1), depth0)) != null ? stack1 : "")
    + "</h3>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "t-extend-h2";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../../../../../core/helpers/if-cond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"title") : depth0),"and",((stack1 = (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? lookupProperty(stack1,"titleText") : stack1),{"name":"if-cond","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":3,"column":12}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = __default(require("../../../../../../core/helpers/if-cond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"subTitle") : depth0),"and",((stack1 = (depth0 != null ? lookupProperty(depth0,"subTitle") : depth0)) != null ? lookupProperty(stack1,"titleText") : stack1),{"name":"if-cond","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":0},"end":{"line":7,"column":12}}})) != null ? stack1 : "")
    + "\n<div class=\"t-control-link js-toggle-all-drawers l-margin-top l-float-right\">\n    <span class=\"js-toggle-label\" data-is-expand=\"true\" data-toggle-lable=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"toggleCollapseLabel") : depth0), depth0))
    + "\">"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"toggleExpandLabel") : depth0), depth0))
    + "</span>\n    <span class=\"t-caption-icon t-icon-arrow-down\"></span>\n</div>\n";
},"useData":true});