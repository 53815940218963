
/* Component Dependencies */
var cardTextTemplate = require('templates/cardText.hbs');
var cardTextWithHeadingTemplate = require('templates/cardText-headingWithSubtitle.hbs');
var cardTextWithHeadingMainTemplate = require('templates/cardText-heading.hbs');
var cardTextPopupWithPrintTemplate = require('templates/cardText-popupWithPrint.hbs');
var cardTextTAGP = require('templates/cardText-tagp.hbs')
var cardTextDrawerSectionHeadline = require('templates/cardText-drawerSectionHeadline.hbs');
var cardTextDescriptionWithLinkTemplate = require('templates/cardText-descriptionWithLink.hbs')
var AriesComponent = require('libs/aries-component');
var Popup = require('libs/popup');

AriesComponent.create({

  type: 'cardText',

  template: {
    'cardText': cardTextTemplate,
    'cardText-headingWithSubtitle': cardTextWithHeadingTemplate,
    'cardText-heading': cardTextWithHeadingMainTemplate,
    'cardText-tagp': cardTextTAGP,
    'cardText-drawerSectionHeadline': cardTextDrawerSectionHeadline,
    'cardText-descriptionWithLink': cardTextDescriptionWithLinkTemplate,
    'cardText-popupWithPrint': cardTextPopupWithPrintTemplate
  },
  init: function() {
   
  },
  unBindEvents: function($) {
    $('*[data-component-id="' + this.$el.data('component-id') + '"]').off();
    $('*[data-component-id="' + this.$el.data('component-id') + '"] *').off();
  },

  bindEvents: function() {
    var _self = this;
    var $cardText = $('.cardText');
    if ($cardText.length > 0) {
      var popUpFlag = $cardText.data('popup'),
      showPrintButtonFlag = $cardText.data('showPrintButton');
      if(popUpFlag ) {
        var transferPointsPopup = new Popup({
          selector: '.tile-card-text .mi-popover',
          sourceBlock: '.modal-content',
          alternateCloseMarkup: true
        });
        if(showPrintButtonFlag) {
          transferPointsPopup = new Popup({
            selector: '.tile-card-text .mi-popover',
            sourceBlock: '.modal-content',
            alternateCloseMarkup: false,
            showPrintButton: true
          });
        }
        transferPointsPopup.register();
      }
    }
    if (_self.$el.find('.js-learn-more-link').length){
      var efolioPopup = new Popup({
        reverseCloseIcon: false,
        selector: '.tile-card-text-description-with-link .mi-popover',
        mainClass: 'm-modal t-modal-med',
        sourceBlock: '.modal-content'
      });
      efolioPopup.register();
    }
  }

});
